import React from "react"

import {
  Button,
  Modal,
  Icon,
  Segment
} from "semantic-ui-react";


// some inline styles (we should move these to our index.css at one stage)
const segmentLEZstyle = {
  zIndex: 999,
  position: "absolute",
  width: "360px",
  top: "20px",
  left: "360px",
  maxHeight: "calc(100vh - 5vw)",
  overflow: "auto",
  padding: "15px"
};


class UpdateModal extends React.Component {

  state = {
    modalOpen: true
  }


  handleOpenModal = () => {
    this.setState({ modalOpen: true });
  }

  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  }


  render() {


    if (this.state.modalOpen) {
      return (

        <Modal dimmer="inverted" open={this.state.modalOpen} onClose={this.handleCloseModal} className="modal__welcome-screen">

          <div className="welcome-screen">

            <button className="welcome-screen__close" onClick={this.handleCloseModal}><Icon name="close"></Icon></button>

            <div className="welcome-screen__header">
              <Icon name="warning sign" className="welcome-screen__logo"></Icon>
              <h1 className="welcome-screen__headline">Road Work Ahead: Update</h1>
            </div>

            <p className="welcome-screen__msg">
              Our routing will be temporarily unavailable while we upload the latest improvements of our LEZ routing engine. <br></br><br></br>
            </p>

            <div className="welcome-screen__email-wrapper">
              <p>We'll be back online today (July 10th) until 13.00 CET. Thank you for your patience.</p>
            </div>

            <div className="welcome-screen__button-two">
              <Button className="button-send-email" onClick={this.handleCloseModal}>Explore the map</Button>
            </div>

          </div>

        </Modal>

      )
    } else {
      return (
        <div className="alertBtn">
          <Button basic color='green' icon="warning circle" onClick={this.handleOpenModal}></Button>
        </div>
      )
    }
  }
}



export default UpdateModal